import Dexie, { Table } from "dexie";

interface Login {
  username: string;
  password: string;
}

const logindb = new Dexie("LoginDatabase") as Dexie & {
  logins: Table<Login, string>;
};
logindb.version(1).stores({
  logins: "username",
});

export async function saveLogin(username: string, password: string) {
  await logindb.logins.put({ username, password });
}

export async function getSavedUsers(): Promise<string[]> {
  return await logindb.logins.toCollection().primaryKeys();
}

export async function getSavedLogin(
  username: string,
): Promise<Login | undefined> {
  return await logindb.logins.get(username);
}

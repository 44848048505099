<template>
  <div class="panel-heading">
    <div class="level is-mobile">
      <div class="level-left">Add members</div>
      <div class="level-right level is-mobile">
        <input
          type="submit"
          class="button is-small"
          value="Add"
          :disabled="!online || !validInput"
          @click="submit"
        />
        <button class="button is-small" @click="emit('cancel')">Cancel</button>
      </div>
    </div>
  </div>
  <div class="column">
    <table class="table is-fullwidth">
      <thead>
        <tr>
          <th>Name</th>
          <th>Email</th>
          <th>Role</th>
        </tr>
      </thead>
      <tbody>
        <AddMembersLine
          v-for="member in members"
          :key="member.id"
          :member="member"
          :roles="group.parts"
          @change="change"
        />
      </tbody>
    </table>
    <div class="field">
      <input
        v-model="pasteBuffer"
        type="text"
        class="input"
        placeholder="Paste list of emails here"
        @paste="paste"
      />
    </div>
    <div class="field">
      <label class="label">Message</label>
      <div class="control">
        <textarea
          v-model="message"
          class="textarea"
          placeholder="Add a message to explain the invitation"
        ></textarea>
      </div>
    </div>
    <div class="field">
      <p v-if="!validInput" class="help is-danger">The form is not complete</p>
    </div>
    <p v-if="error" class="help is-danger">
      {{ error }}
    </p>
  </div>
</template>
<script setup lang="ts">
import { computed, Ref, ref, watch } from "vue";
import AddMembersLine from "./AddMembersLine.vue";
import { online } from "../online";
import { Group } from "../../shared/group";
import { reloadGroups } from "../loaders/groups";
import { EMAIL_RE } from "../../shared/email";
import { createId } from "../../shared/ids";
import { logError } from "../errors";
import { api } from "../api-client";
import { NewMember } from "../../shared/api";

const props = defineProps<{
  group: Group;
}>();
const emit = defineEmits<(e: "cancel" | "done") => void>();

const members: Ref<NewMember[]> = ref([]);
const pasteBuffer = ref("");
watch(pasteBuffer, () => {
  pasteBuffer.value = "";
});
const message = ref("");
const error = ref("");

function memberIsValid(member: NewMember): boolean {
  return member.name.length > 0 && EMAIL_RE.test(member.email);
}
function memberIsEmpty(member: NewMember): boolean {
  return member.name.length === 0 && member.email.length === 0;
}
function countValidMembers(): number {
  return members.value.reduce((a, m) => (memberIsValid(m) ? a + 1 : a), 0);
}
function countEmptyMembers(): number {
  return members.value.reduce((a, m) => (memberIsEmpty(m) ? a + 1 : a), 0);
}
const validInput = computed(() => {
  const validMembers = countValidMembers();
  const emptyMembers = countEmptyMembers();
  return (
    validMembers > 0 &&
    validMembers + emptyMembers === members.value.length &&
    message.value.length > 15
  );
});
function ensureOneEmptyRow() {
  const validMembers = countValidMembers();
  const emptyMembers = countEmptyMembers();
  const lastMember = members.value[members.value.length - 1];
  if (
    !lastMember ||
    // if there are only valid and empty members and the last one is not empty
    (validMembers + emptyMembers === members.value.length &&
      !memberIsEmpty(lastMember))
  ) {
    members.value.push({
      id: createId(),
      name: "",
      email: "",
      role: null,
    });
  }
}
watch(members, ensureOneEmptyRow, { immediate: true });
function setError(msg: string) {
  error.value = msg;
  logError(msg);
  alert(msg);
}
async function submit() {
  error.value = "";
  const body = {
    groupname: props.group.groupname,
    members: members.value.filter(memberIsValid),
    message: message.value,
  };
  const response = await api.addMembers(body);
  if (response.isOk) {
    members.value = [];
    reloadGroups();
    emit("done");
  } else {
    setError(response.value.toString());
  }
}
function change(member: NewMember) {
  const mem = members.value.find((m) => m.id === member.id);
  if (mem) {
    mem.name = member.name;
    mem.email = member.email;
    mem.role = member.role;
  }
  ensureOneEmptyRow();
}
function paste(e: ClipboardEvent) {
  const text = e.clipboardData?.getData("text");
  if (text) {
    addMembersFromText(text);
    ensureOneEmptyRow();
  }
  e.preventDefault();
}
function addMember(name: string, email: string) {
  if (!members.value.find((m) => m.email === email)) {
    members.value.push({
      id: createId(),
      name,
      email,
      role: null,
    });
  }
}
const NAME_MAIL_RE = /^([^<]*)<([^>]+)>$/;
function addMembersFromText(text: string) {
  // if the last member is empty, remove it before adding new members
  const lastMember = members.value[members.value.length - 1];
  if (lastMember && memberIsEmpty(lastMember)) {
    members.value.pop();
  }
  // parse a list of emails of the form
  // "name <name@host.nl>, name2 <name2@host.nl>"
  for (const t of text.split(/[,\r\n]/)) {
    const t2 = t.trim().normalize();
    const m = NAME_MAIL_RE.exec(t2);
    if (m?.[1] && m[2]) {
      addMember(m[1].trim(), m[2].trim());
    } else {
      addMember("", t2);
    }
  }
}
</script>

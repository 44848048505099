<template>
  <div
    ref="pdfcontainer"
    class="pdfcontainer"
    :class="{ 'touch-action-none': disableTouch() }"
    @pointerdown="pointerDown"
    @pointermove="pointerMove"
    @pointerup="pointerUp"
    @pointercancel="pointerUp"
    @pointerout="pointerUp"
    @pointerleave="pointerUp"
    @touchdown="touchDown"
    @touchmove="touchMove"
  >
    <div ref="pdfparent" class="pdfview" :style="'zoom:' + scale">
      <VuePdfEmbed
        v-if="source"
        ref="pdf"
        v-bind="pdfArgs"
        :source
        :disable-annotation-layer="true"
        :disable-text-layer="true"
        :rotation="options.rotation"
        @rendered="updateCanvases()"
        @loaded="loaded"
      />
      <Annotations
        :canvases
        :scale
        :mode="options.mode"
        :color="options.color"
        @change="(n, a) => emit('annotationsChange', n, a)"
        @mode-change="modeChange"
        @move-item="moveItem"
      ></Annotations>
    </div>
  </div>
</template>
<script setup lang="ts">
import {
  defineAsyncComponent,
  onMounted,
  onUnmounted,
  ref,
  Ref,
  watch,
} from "vue";
import Annotations from "../components/Annotations.vue";
import {
  PageAnnotations,
  PartAnnotations,
  Point,
} from "../../shared/annotations";
import { PDFDocumentProxy } from "pdfjs-dist";
import { PdfArgs, PdfViewMode, PdfViewOptions } from "./PdfViewOptions";
import { Canvas } from "./canvas";

const props = defineProps<{
  group: string;
  source: Uint8Array<ArrayBuffer> | undefined;
  options: PdfViewOptions;
  annotations: PartAnnotations;
}>();
const emit = defineEmits<{
  (e: "change", options: PdfViewOptions): void;
  (e: "annotationsChange", canvas: number, annotations: PageAnnotations): void;
}>();

const VuePdfEmbed = defineAsyncComponent(() => import("vue-pdf-embed"));
const pdfcontainer: Ref<HTMLDivElement | undefined> = ref();
const pdfparent: Ref<HTMLDivElement | undefined> = ref();
const pdf: Ref<InstanceType<typeof VuePdfEmbed> | undefined> = ref();
const canvases: Ref<Canvas[]> = ref([]);
const scale: Ref<number> = ref(1);
const pdfArgs: Ref<PdfArgs> = ref({});

watch(
  () => props.options,
  () => {
    setViewport();
  },
  { immediate: true },
);

function onResize() {
  setViewport();
  updateCanvases();
}
function rectsEqual(a: DOMRect, b: DOMRect): boolean {
  return (
    a.top === b.top &&
    a.left === b.left &&
    a.width === b.width &&
    a.height === b.height
  );
}
function updateCanvases() {
  if (pdfparent.value) {
    const children = Array.from(
      pdfparent.value.querySelectorAll("div.vue-pdf-embed canvas"),
    );
    const newCanvases: Canvas[] = [];
    let sawChange = false;
    for (const [index, canvas] of children.entries()) {
      const rect = canvas.getBoundingClientRect();
      const prevCanvas = canvases.value[index];
      const a = props.annotations.pageAnnotations[index] ??
        prevCanvas?.annotations ?? { items: [] };
      newCanvases.push({
        rect,
        annotations: a,
        // assuming A4 if no dimension is known
        pageWidthPt: prevCanvas?.pageWidthPt ?? 595,
        pageHeightPt: prevCanvas?.pageHeightPt ?? 842,
      });
      if (
        !prevCanvas ||
        !rectsEqual(rect, prevCanvas.rect) ||
        a.items.length !== prevCanvas.annotations.items.length
      ) {
        sawChange = true;
      }
    }
    if (sawChange || newCanvases.length !== canvases.value.length) {
      canvases.value = newCanvases;
    }
  }
  if (pdf.value) {
    if (pdf.value.scale > 0) {
      scale.value = pdf.value.scale;
    } else {
      scale.value = 1;
    }
  }
}

function setViewport() {
  if (!pdfcontainer.value) {
    setTimeout(setViewport, 10);
    return;
  }
  const w = pdfcontainer.value.clientWidth;
  const h = pdfcontainer.value.clientHeight;
  if (props.options.pageFit !== "custom") {
    scale.value = 1;
  }
  if (props.options.rotation % 180 === 0) {
    if (props.options.pageFit === "fit-width") {
      pdfArgs.value = { width: w };
    } else if (props.options.pageFit === "fit-height") {
      pdfArgs.value = { height: h };
    }
  } else {
    if (props.options.pageFit === "fit-width") {
      pdfArgs.value = { height: w };
    } else if (props.options.pageFit === "fit-height") {
      pdfArgs.value = { width: h };
    }
  }
}

function moveItem(index: number, id: string, offset: Point) {
  const page = props.annotations.pageAnnotations[index];
  if (page === undefined) {
    return;
  }
  const item = page.items.find((i) => i.id === id);
  if (item?.type === "path") {
    const d = item.d;
    const dx = offset.x;
    const dy = offset.y;
    for (const c of d) {
      for (const b of c) {
        b.x += dx;
        b.y += dy;
      }
    }
    emit("annotationsChange", index, page);
  } else if (item?.type === "text") {
    item.x += offset.x;
    item.y += offset.y;
    emit("annotationsChange", index, page);
  }
}
async function loaded(doc: PDFDocumentProxy) {
  const c = canvases.value;
  for (let i = 0; i < doc.numPages; ++i) {
    const view = (await doc.getPage(i + 1)).view;
    if (
      view[0] === undefined ||
      !Number.isFinite(view[0]) ||
      view[1] === undefined ||
      !Number.isFinite(view[1]) ||
      view[2] === undefined ||
      !Number.isFinite(view[2]) ||
      view[3] === undefined ||
      !Number.isFinite(view[3])
    ) {
      continue;
    }
    const pageWidthPt = view[2] - view[0];
    const pageHeightPt = view[3] - view[1];
    const canvas = c[i];
    if (canvas === undefined) {
      c[i] = {
        rect: new DOMRect(0, 0, 100, 100),
        annotations: props.annotations.pageAnnotations[i] ?? {
          items: [],
        },
        pageWidthPt,
        pageHeightPt,
      };
    } else {
      canvas.pageWidthPt = view[2] - view[0];
      canvas.pageHeightPt = view[3] - view[1];
    }
  }
}
async function download(filename: string) {
  await pdf.value?.download(filename);
}
defineExpose({ download });

let pointer1: PointerEvent | undefined;
let pointer2: PointerEvent | undefined;
let initialPoint = { x: 0, y: 0 };
let initialDiff = 0;
let initialScale: number | undefined;

// Calculate the distance between the two pointers
function calculateDiff(p1: PointerEvent, p2: PointerEvent) {
  const dx = p1.clientX - p2.clientX;
  const dy = p1.clientY - p2.clientY;
  return Math.sqrt(dx * dx + dy * dy);
}

function disableTouch(): boolean {
  return props.options.mode.isDraw;
}

function pointerDown(e: PointerEvent) {
  if (e.pointerType !== "touch" || disableTouch()) {
    return;
  }
  if (pointer1) {
    if (pointer2) {
      return;
    }
    pointer2 = e;
  } else {
    pointer1 = e;
    return;
  }
  if (pdfcontainer.value) {
    // find the location of the first pointer in coordinates of the pdfparent
    initialPoint = {
      x: (pointer1.x + pdfcontainer.value.scrollLeft) / scale.value,
      y: (pointer1.y + pdfcontainer.value.scrollTop) / scale.value,
    };
    initialDiff = calculateDiff(pointer1, pointer2);
    initialScale = scale.value;
  }
}
function touchDown(e: TouchEvent) {
  if (e.touches.length > 1) {
    e.stopImmediatePropagation();
    e.preventDefault();
  }
}
function touchMove(e: TouchEvent) {
  if (e.touches.length > 1) {
    e.stopImmediatePropagation();
    e.preventDefault();
  }
}
function pointerMove(e: PointerEvent) {
  if (!pointer1) {
    return;
  }
  if (!pointer2) {
    if (props.options.mode.isEdit && pdfcontainer.value) {
      // in edit mode, simulate scrolling
      const x = pdfcontainer.value.scrollLeft + pointer1.x - e.x;
      const y = pdfcontainer.value.scrollTop + pointer1.y - e.y;
      pointer1 = e;
      pdfcontainer.value.scrollTo(x, y);
    }
    return;
  }
  // If two pointers are down, check for pinch gestures
  if (pointer1.pointerId === e.pointerId) {
    pointer1 = e;
  } else if (pointer2.pointerId === e.pointerId) {
    pointer2 = e;
  } else {
    return;
  }
  if (initialDiff > 0) {
    // && pointer1.target === pointer2.target) {
    const currentDiff = calculateDiff(pointer1, pointer2);
    // Calculate the distance between the two pointers
    if (currentDiff > 0 && initialScale) {
      const newScale = Math.max(
        0.5,
        Math.min(8, (initialScale * currentDiff) / initialDiff),
      );
      const dx = newScale * initialPoint.x - pointer1.x;
      const dy = newScale * initialPoint.y - pointer1.y;
      scale.value = newScale;
      emit("change", { ...props.options, pageFit: "custom" });
      window.requestAnimationFrame(() => {
        pdfcontainer.value?.scrollTo(dx, dy);
      });
    }
  }
}

function modeChange(mode: PdfViewMode) {
  emit("change", { ...props.options, mode });
}

function pointerUp(e: PointerEvent) {
  if (
    pointer1?.pointerId === e.pointerId ||
    pointer2?.pointerId === e.pointerId
  ) {
    pointer1 = undefined;
    pointer2 = undefined;
  }
}

onMounted(() => {
  window.addEventListener("resize", onResize);
  updateCanvases();
});
onUnmounted(() => {
  window.removeEventListener("resize", onResize);
});
</script>
<style>
div.pdfview {
  position: relative;
}
div.pdfcontainer {
  width: 100%;
  height: 100%;
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  scroll-behavior: auto;
  /* avoid text selection in the annotations */
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}
div.pdfcontainer::-webkit-scrollbar {
  display: none;
}
div.pdfcontainer.touch-action-none {
  touch-action: none;
}
nav.menu {
  position: fixed;
  top: 0;
  right: 0;
}
</style>
